.App{
  display: grid;
}

body{
  background-image: url("./images/bgtrial.png");
  background-repeat: no-repeat;
  background-size: cover;
}

*{
  padding: 0;
  margin: 0;
}

:root{
  --hanifeGold: linear-gradient(45deg, brown, gold)
}

h1, h2, h3{
  font-family: 'Cinzel', serif;
}

span, a, button{
  font-family: 'Lexend', sans-serif;
}

#Header{
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(46,38,45);
  align-items: center;
  height: calc(100vh - 10px);
  padding: 0 0 10px 0;
  width: 300px;
  position: fixed;
  z-index: 100;
}

#Menu{
  display: grid;
  text-align: center;
  width: 100%;
}

#Menu a{
  padding: 20px;
}

#Menu a:hover{
  background: #FFFFFF30;
}

#Hero{
  display: grid;
  margin-left: 300px;
}

#HeroTop{
  display: flex;
  justify-self: center;
  align-items: center;
  width: calc(100vw - 300px);
  height: 760px;
  background-image: url("./images/hanifebg.png");
  background-size: fill;
  color: white;
}

#HeroButtons{
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
}

#Hero button{
  background: none;
  border: 1px solid white;
  color: white;
  padding: 10px;
}

#HeroSpacer{
  width: 60%;
}

#HeroCell{
  display: grid;
  height: fit-content;
  justify-content: center;
  text-align: center;
  gap: 10px;
  max-width: 420px;
}

#HeroBottom{
  display: grid;
  grid-auto-flow: column;
  width: calc(100vw - 300px);
  height: calc(100vh - 760px);
  grid-template-columns: 1fr 3fr;
}
#HeroBottomLeft{
  text-align: center;
  align-self: center;
  display: grid;
  gap: 5px;
}
#HeroBottomRightCell{
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
}

#HeroBottomRightCell img{
  width: 75px;
  align-self: center;
  filter: opacity(70%);
}

#GoldRibbon{
  height: 8px;
  width: 5vw;
  margin-left: 3.88vw;
  background: linear-gradient(0deg, rgb(224,210,140), rgb(207,174,85));
}
#HeroBottomRight{
  display: flex;
  justify-content: space-evenly;
  background: linear-gradient(90deg, rgb(224,210,140), rgb(207,174,85));
  align-items: center;
  color: white;
}

#HeroButtons button:hover{
  cursor: pointer;
  background: white;
  color: black;
}

#HKimza{
  width: 25vw;
  justify-self: center;
}

a{
  text-decoration: none;
  color: white;
}

#SiteID{
  display: grid;
  text-align: center;
}

#SiteID span{
  color: #FFFFFF30;
}

#Hakkimizda, #Vizyonumuz, #Misyonumuz, #Sinavlarimiz, #Markalarimiz, #Workshoplarimiz, #Distributor, #Contact{
  display: grid;
  margin-left: 300px;
  padding: 30px;
  text-align: center;
  gap: 20px;
}

#Hakkimizda h1, #Hakkimizda h2, 
#Vizyonumuz h1, 
#Misyonumuz h1, 
#Sinavlarimiz h1, #Sinavlarimiz h2, 
#Markalarimiz h1, 
#Workshoplarimiz h1, #Workshoplarimiz h2, 
#Distributor h1, 
#Contact h1, #Contact h2, #Contact h3{
  color: rgb(133, 111, 55);
}

#CellContainer{
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
}

#HakkimizdaCell{
  display: grid;
  border: 2px solid gray;
  border-radius: 30px;
  padding: 20px;
  backdrop-filter: blur(20px);
}

#HakkimizdaCell img{
  width: 120px;
  justify-self: center;
}

#MisyonumuzCell{
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 30px;
  justify-self: center;
}

#MisyonumuzCell img, #VizyonumuzCell img{
  width: 400px;
  border-radius: 30px;
  border: 3px solid goldenrod;
}

#VizyonumuzCell{
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 10px;
  width: fit-content;
  justify-self: center;
  text-align: justify;
  padding: 20px;
  border: 3px solid goldenrod;
  border-radius: 30px;
  backdrop-filter: blur(30px) brightness(100%);
}

#VizyonumuzCell span{
  max-width: 400px;
}

#MarkalarimizContainer{
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;
  justify-self: center;
  gap: 30px;
}

#MarkalarimizCell{
  display: grid;
  justify-self: center;
  justify-items: center;
  gap: 10px;
  max-width: 450px;
  width: 25vw;
  padding: 20px;
  border: 3px solid goldenrod;
  border-radius: 30px;
  backdrop-filter: blur(30px) brightness(100%);
  height: stretch;
  text-align: justify;
}

#MarkalarimizCell img{
  max-width: 450px;
  width: 25vw;
}

#ContactCell{
  display: flex;
  justify-self: center;
  align-items: center;
  text-align: left;
  width: 80%;
  gap: 30px ;
}

#ContactText{
  display: flex;
  gap: 10px;
  justify-self: center;
  align-items: flex-start;
}

@media only screen and (max-width: 1400px){
  #HeroTop{
    display: flex;
    justify-self: center;
    align-items: center;
    width: calc(100vw - 300px);
    height: calc(100vh - 100px);
    background-image: url("./images/hanifebg.png");
    background-size: auto calc(100vh - 100px);
    color: white;
  }
  #HeroBottom{
    height: 100px;
  }
  #VizyonumuzCell img{
    width: 250px;
  }
  #MisyonumuzCell img{
    width: 250px;
  }
}

@media only screen and (max-width: 450px){
  #MobileHeader{
    display: grid;
    position: absolute;
    left: 20px;
    top: 20px;
  }
  #MobileHeader button{
    padding: 10px;
    background: rgba(46,38,45);
    color: white;
    border: none;
    border-radius: 10px;
  }
  #Header{
    color: white;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(46,38,45);
    align-items: center;
    height: calc(91vh - 7px);
    width: 60vw;
    left: 0;
    top: 0;
    padding-bottom: 20px;
  }
  #Hero{
    margin: 0;
    padding: 0;
    gap: 0;
    height: 100%;
  }
  #HeroTop{
    width: calc(100vw - 0px);
    height: 84vh;
    margin: 0;
    background-image: none;
    background-color: black;
    padding: 0 0px;
  }
  #HeroSpacer{
    width: 0;
    height: 0;
    padding: 0;
    display: none;
    visibility: hidden;
  }
  #HeroCell{
    display: grid;
    text-align: center;
    gap: 10px;
    padding: 0 30px;
  }
  #HeroBottom{
    width: 100vw;
    height: 10vw;
    padding: 0;
  }
  #HeroBottomLeft{
    font-size: 12px;
  }
  #HeroBottomRightCell{
    font-size: 8px;
  }
  #HeroBottomRightCell img{
    width: 30px;
    align-self: center;
    filter: opacity(70%);
  }
  #Hakkimizda, #Vizyonumuz, #Misyonumuz, #Sinavlarimiz, #Markalarimiz, #Workshoplarimiz, #Distributor, #Contact{
    display: grid;
    margin-left: 0px;
    padding: 30px;
    text-align: center;
    gap: 20px;
  }
  #CellContainer{
    display: grid;
    grid-auto-flow: row;
    gap: 20px;
  }
  #VizyonumuzCell{
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    gap: 10px;
    width: fit-content;
    justify-self: center;
    text-align: justify;
    padding: 20px;
    border: 3px solid goldenrod;
    border-radius: 30px;
    backdrop-filter: blur(30px) brightness(100%);
  }
  #MisyonumuzCell img, #VizyonumuzCell img{
    width: 100%;
    border-radius: 30px;
    border: 3px solid goldenrod;
  }
  #MisyonumuzCell{
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    gap: 30px;
    justify-self: center;
  }
  #MarkalarimizContainer{
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    width: fit-content;
    justify-self: center;
    gap: 30px;
  }
  #MarkalarimizCell{
    display: grid;
    justify-self: center;
    justify-items: center;
    gap: 10px;
    width: 90%;
    padding: 20px;
    border: 3px solid goldenrod;
    border-radius: 30px;
    backdrop-filter: blur(30px) brightness(100%);
    height: stretch;
    text-align: justify;
  }
  #MarkalarimizCell img{
    max-width: 450px;
    width: 100%;
  }
  #Workshoplarimiz, #Hakkimizda, #Vizyonumuz, #Misyonumuz, #Sinavlarimiz, #Markalarimiz, #Workshoplarimiz, #Distributor, #Contact{
    padding-top: 70px;
  }
}